import React from "react";
import PropTypes from "prop-types";
import { Container, Grid, Divider, Hidden} from "@material-ui/core";
import "./layout.css"
import ScrollAnimation from 'react-animate-on-scroll';
import showdown from 'showdown';
import { Element } from 'react-scroll';
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function ProtocolSection(props){

const converter = new showdown.Converter();

  const {
    heading,
    precedenceHeading,
    precedenceDescription,
    precedenceImage,
    addressingHeading,
    addressingDescription,
    addressingImage
  } = props;

  const preImage = getImage(precedenceImage);
  const addImage = getImage(addressingImage);

  return (
    <Element name="protocol-section">
      <section>
        <ScrollAnimation animateIn="fadeIn">
          <Container maxWidth="md">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} className="sectionHeaderCenter">
                <h2>{heading}</h2>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={12} md={8}>
                <h3>
                  {precedenceHeading}
                </h3>
                <div className="bodyText" dangerouslySetInnerHTML={{ __html: converter.makeHtml(precedenceDescription) }} />
              </Grid>
              <Hidden smDown>
                <Grid item xs={12} sm={4} md={4}>
                  <GatsbyImage placeholder="none" image={preImage} alt={"Precendence graphic"} className="protocolImage" />
                </Grid>
              </Hidden>
              <Divider className="protocolDivider" />
              <Hidden smDown>
                <Grid item xs={12} sm={4} md={4}>
                  <GatsbyImage placeholder="none" image={addImage} alt={"Addressing graphic"} className="addressingImage" />
                </Grid>
              </Hidden>
              <Grid item xs={12} sm={12} md={8}>
                <h3>
                  {addressingHeading}
                </h3>
                <div className="bodyText" dangerouslySetInnerHTML={{ __html: converter.makeHtml(addressingDescription) }} />
              </Grid>
            </Grid>
          </Container>
        </ScrollAnimation>
      </section>
    </Element>
  );
};

ProtocolSection.propTypes = {
props: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      precedenceHeading: PropTypes.string,
      precedenceDescription: PropTypes.string,
      precedenceImage: PropTypes.string,
      addressingHeading: PropTypes.string,
      addressingDescription: PropTypes.string,
      addressingImage: PropTypes.string,
    })
  ),
};

