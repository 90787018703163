import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Container, Grid, Divider, Box} from "@material-ui/core";
import Layout from "../components/Layout";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import FixedNavbar from "../components/FixedNavbar";
import Footer from "../components/Footer";
import showdown from 'showdown';
import RoleSection from "../components/RoleSection";
import ProtocolSection from "../components/ProtocolSection";

// eslint-disable-next-line
export const CurrentHighSheriffPageTemplate = ({ heading, description, image, roleheading, roledescription,protocolheading, protocolprecedenceheading, protocolprecedencedescription, protocolprecedenceimage, protocoladdressingheading, protocoladdressingdescription, protocoladdressingimage  }) => {
  const converter = new showdown.Converter();
  const aboutImage = getImage(image);

  return (
    <>
      <FixedNavbar />
      <section >
        <Container maxWidth="md">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} className="sectionHeaderCenter">
              <h2>{heading}</h2>
              <Divider />
            </Grid>
            <Grid item xs={12} sm={12} md={aboutImage ? 9 : 12}>
              <div className="bodyText" dangerouslySetInnerHTML={{ __html: converter.makeHtml(description) }} />
            </Grid>
            {aboutImage && (
              <Grid item xs={12} sm={12} md={3}>
                <Box className="aboutImageContainer">
                  <GatsbyImage placeholder="blurred" image={aboutImage} alt={"High Sheriff of County Down - Prof Mike Mawhinney MBE TD"} className="aboutImage" />
                </Box>
              </Grid>
            )}
          </Grid>
        </Container>
      </section>
      <div id="role-section">
        <RoleSection heading={roleheading} description={roledescription} />
      </div>  
      <div id="protocol-section">
        <ProtocolSection 
          heading={protocolheading} 
          precedenceHeading={protocolprecedenceheading} 
          precedenceDescription={protocolprecedencedescription} 
          precedenceImage={protocolprecedenceimage}
          addressingHeading={protocoladdressingheading} 
          addressingDescription={protocoladdressingdescription}
          addressingImage={protocoladdressingimage}/>
      </div>
      <Footer />
    </>
  );
};

CurrentHighSheriffPageTemplate.propTypes = {
  heading: PropTypes.string.isRequired,
};

const CurrentHighSheriffPage = ({ data }) => {

    const { markdownRemark: post } = data;


  return (
    <Layout>
      <CurrentHighSheriffPageTemplate 
        heading={post.frontmatter.heading} 
        description={post.frontmatter.description} 
        image={post.frontmatter.image} 
        roleheading={post.frontmatter.roleheading} 
        roledescription={post.frontmatter.roledescription} 
        protocolheading={post.frontmatter.protocolheading} 
        protocolprecedenceheading={post.frontmatter.protocolprecedenceheading} 
        protocolprecedencedescription={post.frontmatter.protocolprecedencedescription} 
        protocolprecedenceimage={post.frontmatter.protocolprecedenceimage} 
        protocoladdressingheading={post.frontmatter.protocoladdressingheading} 
        protocoladdressingdescription={post.frontmatter.protocoladdressingdescription} 
        protocoladdressingimage={post.frontmatter.protocoladdressingimage} 
        />
    </Layout>
  );
};

CurrentHighSheriffPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CurrentHighSheriffPage;

export const CurrentHighSheriffPageQuery = graphql`
  query CurrentHighSheriffPageTemplate {
    markdownRemark(frontmatter: {templateKey: {eq: "currenthighsheriff-page"}}) {
      frontmatter {
        heading
        description
        image {
          childImageSharp {
            gatsbyImageData(
              quality: 100
            )
          }
        }
        roleheading
        roledescription
        protocolheading
        protocolprecedenceheading
        protocolprecedencedescription
        protocolprecedenceimage {
          childImageSharp {
            gatsbyImageData(
              quality: 100
            )
          }
        }
        protocoladdressingheading
        protocoladdressingdescription
        protocoladdressingimage {
          childImageSharp {
            gatsbyImageData(
              quality: 100
            )
          }
        }
      }
    }
  }
`;
